import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import Logo from "../../img/logo.svg";
import { useAuth } from "../../store/auth";
import { shallow } from "zustand/shallow";

const Auth = () => {
  const [login, loginErrorMessage, loading] = useAuth(
    (state) => [state.login, state.loginErrorMessage, state.loading],
    shallow,
  );
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (loginErrorMessage) {
      error(loginErrorMessage);
    }
  }, [loginErrorMessage]);

  const error = (content) => {
    messageApi.open({
      type: "error",
      content,
    });
  };
  const onFinish = (values) => login(values);
  const onFinishFailed = (errorInfo) => {};

  const onFieldsChange = () => {};

  return (
    <div style={{ maxWidth: "600px", margin: "80px auto" }}>
      {contextHolder}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={Logo} alt={Logo} width={128} />
      </div>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        autoComplete="on"
      >
        <Form.Item
          label="Логин"
          name="email"
          rules={[
            { required: true, message: "Пожалуйста, введите свой логин!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[
            { required: true, message: "Пожалуйста, введите свой пароль!" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 4, span: 16 }}
        >
          <Checkbox>Запомнить меня</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Auth;
