import { create } from "zustand";
import { api } from "../api";
export const useAuth = create((set) => ({
  loading: false,
  isWhoAmI: false,
  isLogin: false,
  loginErrorMessage: "",
  getWhoAmI: async () => {
    const isWhoAmI = await api.authApi
      .whoAmIWithHttpInfo()
      .then(() => {
        set({ isLogin: true });
        return true;
      })
      .catch((e) => {
        if (!e.response.ok) {
          console.error();
          return false;
        }
      });
    set({ isWhoAmI, isLogin: isWhoAmI });
  },
  login: async (values) => {
    set({ loading: true, loginErrorMessage: "" });
    await api.authApi
      .logInWithHttpInfo({ ...values })
      .then(() => {
        set({ isLogin: true, loading: false });
      })
      .catch((e) => {
        set({
          loading: false,
          isLogin: false,
          loginErrorMessage:
            e.body.statusCode === 401
              ? "Неправильный логин или пароль"
              : "Ошибка авторизации",
        });
        console.error(e);
      });
  },
  logout: async () => {
    await api.authApi
      .logOut()
      .then(() => {
        set({ isLogin: false });
      })
      .catch((e) => {
        console.error(e);
      });
  },
}));
