import {
  ApiClient,
  AppApi,
  AuthApi,
  CollectionApi,
  ItemApi,
  PhotoApi,
} from "@solar/api-client-js";

export const api = {
  appApi: new AppApi(new ApiClient("/")),
  authApi: new AuthApi(new ApiClient("/")),
  collectionApi: new CollectionApi(new ApiClient("/")),
  itemApi: new ItemApi(new ApiClient("/")),
  photoApi: new PhotoApi(new ApiClient("/")),
};
