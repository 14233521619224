import { createFormData } from "../utils/data";
export const getItems = async (collectionId) => {
  const response = await fetch(
    `/api/items?sort=createdAt,DESC${
      collectionId ? `&filter=collectionId||$eq||${collectionId}` : ""
    }`,
  );

  if (!response.ok) throw new Error("Unable to get items.");

  return response.json();
};

export const postItem = async (form) => {
  const response = await fetch(`/api/items`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to post item." };
  }

  return response.json();
};

export const editItem = async (id, form) => {
  const response = await fetch(`/api/items/${id}`, {
    method: "PUT",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error("Unable to edit item.");

  return response.json();
};

export const deleteItem = async (id) => {
  const response = await fetch(`/api/items/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error("Unable to delete item.");

  return;
};

export const getItem = async (id) => {
  const response = await fetch(`/api/items/${id}`);

  if (!response.ok) throw new Error("Unable to get item.");

  return response.json();
};

export const uploadPhoto = async (form) => {
  const response = await fetch("/api/items/upload-photos", {
    method: "POST",
    body: createFormData(form),
  });

  if (!response.ok) {
    return { error: "Unable to upload photo." };
  }

  const data = await response.json();

  return data;
};

export const deletePhoto = async (form) => {
  const response = await fetch("/api/photos", {
    method: "DELETE",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to delete photo." };
  }

  const data = await response.json();

  return { ...data, ...form };
};

export const setPhotoOrder = async (form) => {
  const response = await fetch("/api/items/set-photo-order", {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to set photo order." };
  }

  const data = await response.json();

  return { ...data, ...form };
};

export const setItemOrder = async (form) => {
  const response = await fetch("/api/collections/set-item-order", {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to set item order." };
  }

  const data = await response.json();

  return { ...data, ...form };
};
