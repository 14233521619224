import { create } from "zustand";
import {
  deleteItem,
  getItems,
  postItem,
  getItem,
  editItem,
  uploadPhoto,
  deletePhoto,
  setPhotoOrder,
  setItemOrder,
} from "../services/Items.service";
export const useItems = create((set) => ({
  loadingCreateEdit: false,
  successCreateEdit: false,
  errorCreateEdit: false,
  loadingItems: false,
  items: [],
  item: null,
  successDelete: false,
  deleteItem: async (id) => {
    const items = useItems.getState().items;
    await deleteItem(id);
    set({ items: items.filter((i) => i.id !== id), successDelete: true });
  },
  resetDelete: () => {
    set({ successDelete: false });
  },
  getItem: async (id) => {
    const item = await getItem(id);
    set({
      item: {
        ...item,
        photos: item.photos.sort((a, b) => (a.index > b.index ? 1 : -1)),
      },
    });
  },
  getItems: async (collectionId) => {
    set({ loadingItems: true });

    const response = await getItems(collectionId);
    set({
      loadingItems: false,
      items: response.data.sort((a, b) => (a.index > b.index ? 1 : -1)),
    });
  },
  postItem: async (values) => {
    set({
      loadingCreateEdit: true,
      successCreateEdit: false,
      errorCreateEdit: false,
    });
    const items = await postItem(values);
    if (items.error) {
      set({
        loadingCreateEdit: false,
        successCreateEdit: false,
        errorCreateEdit: true,
      });
    } else {
      set({ loadingCreateEdit: false, successCreateEdit: true });
    }
  },
  resetSuccessError: () => {
    set({ successCreateEdit: false, errorCreateEdit: false });
  },
  resetItem: () => {
    set({ item: null });
  },
  resetItems: () => {
    set({ items: [] });
  },
  editItem: async (id, form) => {
    const stateItem = useItems.getState().item;
    set({ loadingCreateEdit: true, successCreateEdit: false });
    const item = await editItem(id, form);
    set({
      item: { ...item, photos: stateItem.photos },
      loadingCreateEdit: false,
      successCreateEdit: true,
    });
  },
  uploadPhoto: async (form) => {
    const item = useItems.getState().item;
    const data = await uploadPhoto(form);
    if (data.error) {
      return;
    } else {
      set({
        item: {
          ...item,
          photos: item.photos.concat(data),
        },
      });
    }
  },
  deletePhoto: async (form) => {
    const data = await deletePhoto(form);
    const item = useItems.getState().item;
    if (data.error) {
      return;
    } else {
      set({
        item: {
          ...item,
          photos: item.photos.filter((photo) => photo.id !== data.id),
        },
      });
    }
  },
  setPhotoOrder: async (form) => {
    const data = await setPhotoOrder(form);
    const item = useItems.getState().item;
    if (data.error) {
      return;
    } else {
      set({
        item: {
          ...item,
          photos: data.ids.map((id, idx) => {
            const findedPhoto = item.photos.find((photo) => photo.id === id);
            return { ...findedPhoto, index: idx + 1 };
          }),
        },
      });
    }
  },
  setItemOrder: async (form) => {
    const data = await setItemOrder(form);
    const items = useItems.getState().items;

    if (data.error) {
      return;
    } else {
      const filteredItems = items.filter((item) => !form.ids.includes(item.id));

      set({
        items: form.ids
          .map((id, idx) => {
            const findedItem = items.find((item) => item.id === id);
            return { ...findedItem, index: idx + 1 };
          })
          .concat(filteredItems),
      });
    }
  },
}));

// export const createEditItem = create((set) => ({
//   loadingCreateEdit: false,
//   successCreateEdit: false,
//   item: {},
//   postItem: async (values) => {
//     set({ loadingCreateEdit: true, successCreateEdit: false });
//     const items = await postItem(values);
//     set({ loadingCreateEdit: false, items, successCreateEdit: true });
//   },
//   resetSuccessCreateEdit: () => {
//     set({ successCreateEdit: false });
//   },
//   resetItem: () => {
//     set({ item });
//   },
//   editItem: async (id, form) => {
//     set({ loadingCreateEdit: true, successCreateEdit: false });
//     const item = await editItem(id, form);
//     set({ item, loadingCreateEdit: false, successCreateEdit: true });
//   },
// }));
