import React, { useEffect, useState } from "react";
import { Button, Upload, Form, Typography, Card, Image, Tag } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useItems } from "../../../store/items";
import { shallow } from "zustand/shallow";
import noPhoto from "../../../img/no-photo.avif";
import SortableList, { SortableItem } from "react-easy-sort";
import { logDOM } from "@testing-library/react";

const Photo = () => {
  const [item, uploadPhoto, deletePhoto, setPhotoOrder] = useItems(
    (state) => [
      state.item,
      state.uploadPhoto,
      state.deletePhoto,
      state.setPhotoOrder,
    ],
    shallow,
  );

  const [photos, setPhotos] = useState([]);
  const handleRemovePhoto = ({ id }) => {
    deletePhoto({ id });
  };

  useEffect(() => {
    if (item) {
      setPhotos(item.photos);
    }
  }, [item]);

  const onChange = (downloadProps) => {};

  const onSortEnd = (oldIndex, newIndex) => {
    const newPhotosArray = [...photos];
    const actionItem = newPhotosArray.splice(oldIndex, 1)[0];
    newPhotosArray.splice(newIndex, 0, actionItem);

    setPhotoOrder({ itemId: item.id, ids: newPhotosArray.map((i) => i.id) });
  };

  return (
    <Form.Item>
      <Upload
        customRequest={({ file }) => uploadPhoto({ file, itemId: item.id })}
        onRemove={handleRemovePhoto}
        showUploadList={false}
        multiple
        accept="image/png, image/jpeg"
      >
        <Button icon={<UploadOutlined />} style={{ marginBottom: "16px" }}>
          Загрузить фото
        </Button>
      </Upload>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {photos.map((photo) => (
          <SortableItem key={photo.id}>
            <Card style={{ cursor: "grab", marginBottom: "8px" }}>
              <div style={{ display: "flex" }}>
                <Image
                  alt={photo.id}
                  height={100}
                  preview
                  src={photo.url}
                  // style={{ height: "240px", objectFit: "cover" }}
                  onError={(e) => (e.target.src = noPhoto)}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginLeft: "16px",
                  }}
                >
                  <Tag color="blue">{photo.id}</Tag>
                  <Tag
                    color="geekblue"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Позиция {photo.index}
                  </Tag>
                  <Button
                    type="primary"
                    onClick={() => deletePhoto({ id: photo.id })}
                  >
                    Удалить
                  </Button>
                </div>
              </div>
            </Card>
          </SortableItem>
        ))}
      </SortableList>
    </Form.Item>
  );
};

export default Photo;
