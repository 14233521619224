export const typesArray = [
  { name: "Love", id: "LOVE" },
  { name: "Ambition", id: "AMBITION" },
  { name: "Emotions", id: "EMOTION" },
  { name: "Confidence", id: "CONFIDENCE" },
  { name: "Harmony", id: "HARMONY" },
];

export const createFormData = (form) => {
  const formData = new FormData();

  Object.keys(form).forEach((key) => {
    formData.append(key, form[key]);
  });

  return formData;
};
