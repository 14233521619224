import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./Auth";
import { useAuth } from "../store/auth";
import { shallow } from "zustand/shallow";
import RoutesLogged from "./RoutesLogged";

const RoutesComponent = () => {
  const [isWhoAmI, getWhoAmI, isLogin] = useAuth(
    (state) => [state.isWhoAmI, state.getWhoAmI, state.isLogin],
    shallow,
  );
  useEffect(() => {
    getWhoAmI();
  }, [isLogin]);

  return (
    <BrowserRouter>
      {isWhoAmI ? (
        <RoutesLogged />
      ) : (
        <Routes>
          <Route path="/" element={<Auth />} exact />
        </Routes>
      )}
    </BrowserRouter>
  );
};
export default RoutesComponent;
