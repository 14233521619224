import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useItems } from "../../store/items";
import { shallow } from "zustand/shallow";
import {
  Button,
  Select,
  Form,
  Input,
  InputNumber,
  notification,
  Col,
  Row,
} from "antd";
import { typesArray } from "../../utils/data";
import { useCollections } from "../../store/collections";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import Photo from "./Photos";
import { logDOM } from "@testing-library/react";

const ItemCreate = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const [
    item,
    getItem,
    postItem,
    loadingCreateEdit,
    editItem,
    successCreateEdit,
    resetSuccessError,
    resetItem,
    errorCreateEdit,
  ] = useItems(
    (state) => [
      state.item,
      state.getItem,
      state.postItem,
      state.loadingCreateEdit,
      state.editItem,
      state.successCreateEdit,
      state.resetSuccessError,
      state.resetItem,
      state.errorCreateEdit,
      state.uploadPhoto,
      state.deletePhoto,
    ],
    shallow,
  );
  const [collections, getCollections] = useCollections(
    (state) => [
      state.collections,
      state.getCollections,
      state.loadingCollections,
    ],
    shallow,
  );

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        name: item.name,
        type: item.type,
        collectionId: item.collectionId,
        price: item.price,
        code: item.code,
        fabric: item.fabric,
        index: item.index,
        size: item.size,
      });
    }
  }, [item]);

  useEffect(() => {
    if (id !== "create") {
      getItem(id);
    }
  }, [id]);

  useEffect(() => {
    getCollections();

    return () => {
      resetItem();
    };
  }, []);

  useEffect(() => {
    if (successCreateEdit || errorCreateEdit) {
      resetSuccessError();
      if (successCreateEdit) {
        return notification.success({
          message: `Карточка товара ${
            id === "create" ? "создана" : "отредактирована"
          }`,
          icon: (
            <SmileOutlined
              style={{
                color: "#06B600",
              }}
            />
          ),
        });
      }
      return notification.error({
        message: "Не удалось создать карточку товара",
        icon: (
          <FrownOutlined
            style={{
              color: "#DD1313",
            }}
          />
        ),
      });
    }
  }, [successCreateEdit, errorCreateEdit, id]);

  const onFinish = (values) => {
    if (id !== "create") {
      editItem(id, values);
    } else {
      postItem(values);
    }
  };
  const onFinishFailed = () => {};
  const onChange = () => {};
  return (
    <Row>
      <Col span={12}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          form={form}
        >
          <Form.Item
            label="Название"
            name="name"
            rules={[
              { required: true, message: "Пожалуйста, введите название!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Тип"
            name="type"
            rules={[{ required: true, message: "Пожалуйста, выберите тип!" }]}
          >
            <Select
              placeholder="Выберите тип"
              onChange={onChange}
              options={typesArray.map((type) => ({
                value: type.id,
                label: type.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Коллекция"
            name="collectionId"
            rules={[
              { required: true, message: "Пожалуйста, выберите коллекцию!" },
            ]}
          >
            <Select
              placeholder="Выберите коллекцию"
              onChange={onChange}
              options={collections.map((type) => ({
                value: type.id,
                label: type.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Цена"
            name="price"
            rules={[{ required: true, message: "Пожалуйста, введите цену!" }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Артикул"
            name="code"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название артикула!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Ткань"
            name="fabric"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название ткани!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Позиция"
            name="index"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите позицию!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Размер"
            name="size"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите размер!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreateEdit}
            >
              {id !== "create" ? "Сохранить" : "Создать"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={2}></Col>
      {id !== "create" && (
        <Col span={8}>
          <Photo item={item} />
        </Col>
      )}
      <Col span={2}></Col>
    </Row>
  );
};

export default ItemCreate;
