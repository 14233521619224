import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Avatar, Card, Skeleton, Button, Switch } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { shallow } from "zustand/shallow";
import { useCollections } from "../../store/collections";

const { Meta } = Card;
const cardStyles = {
  display: "flex",
  flexDirection: "column",
  flex: "0 1 24%",
  marginBottom: "16px",
  padding: "0 5px",
};
const Collections = () => {
  const [
    collections,
    getCollections,
    loadingCollections,
    archiveCollection,
    loadingArchiveCollectionId,
  ] = useCollections(
    (state) => [
      state.collections,
      state.getCollections,
      state.loadingCollections,
      state.archiveCollection,
      state.loadingArchiveCollectionId,
    ],
    shallow,
  );

  useEffect(() => {
    getCollections();
  }, []);

  const skeletonCard = (
    <div style={cardStyles}>
      <Card
        style={cardStyles}
        actions={[
          <SettingOutlined key="setting" />,
          <EditOutlined key="edit" />,
          <EllipsisOutlined key="ellipsis" />,
        ]}
      >
        <Skeleton loading={loadingCollections} avatar active>
          <Meta
            avatar={
              <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
            }
            title="Card title"
            description="This is the description"
          />
        </Skeleton>
      </Card>
    </div>
  );

  return (
    <div>
      <Button
        style={{
          display: "flex",
          marginBottom: "16px",
        }}
        type="primary"
      >
        <Link to={"/collections/create"}>Создать коллекцию</Link>
      </Button>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {loadingCollections
          ? [...Array(4)].map((i) => skeletonCard)
          : collections.map((collection) => (
              <div style={cardStyles} key={collection.id}>
                <Card
                  key={collection.id}
                  actions={[
                    <Link to={`/collections/${collection.id}`}>
                      <EditOutlined key="edit" />
                    </Link>,
                    <Switch
                      checkedChildren="Архив"
                      unCheckedChildren="Архив"
                      checked={collection.isArchived}
                      loading={collection.id === loadingArchiveCollectionId}
                      onChange={(value) =>
                        archiveCollection(collection.id, collection.isArchived)
                      }
                    />,
                  ]}
                >
                  <Meta
                    title={collection.name}
                    description={
                      collection.price
                        ? `${collection.price.toLocaleString("ru-RU")} ₽`
                        : ""
                    }
                  />
                </Card>
              </div>
            ))}
      </div>
    </div>
  );
};
export default Collections;
