import React, { useEffect, useState } from "react";
import { Button, Upload, Form, Tag, Image, Card } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCollections } from "../../../store/collections";
import { shallow } from "zustand/shallow";
import { BigPlayButton, Player } from "video-react";

const Video = ({ collection }) => {
  const [uploadVideo, deleteVideo] = useCollections(
    (state) => [state.uploadVideo, state.deleteVideo],
    shallow,
  );

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (collection) {
      setVideos(collection.videos);
    }
  }, [collection]);

  return (
    <Form.Item>
      <Upload
        customRequest={({ file }) =>
          uploadVideo({ file, collectionId: collection.id })
        }
        showUploadList={false}
        multiple
        accept="video/mp4"
      >
        <Button icon={<UploadOutlined />} style={{ marginBottom: "16px" }}>
          Загрузить видео
        </Button>
      </Upload>
      {videos.map((video) => (
        <Card style={{ cursor: "grab", marginBottom: "8px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: "16px" }}>
              <Player preload="metadata" src={video.url}>
                <BigPlayButton position="center" />
              </Player>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Tag style={{ width: "100%", marginBottom: "8px" }} color="blue">
                {video.id}
              </Tag>
              <Tag
                color="geekblue"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginBottom: "8px",
                }}
              >
                Позиция {video.index}
              </Tag>
              <Button
                type="primary"
                onClick={() => deleteVideo({ id: video.id })}
              >
                Удалить
              </Button>
            </div>
          </div>
        </Card>
      ))}
    </Form.Item>
  );
};

export default Video;
