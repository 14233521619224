import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { Button, Form, Input, notification, Col, Row } from "antd";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import { usePosts } from "../../store/posts";
import TextEditor from "./TextEditor";

const ItemCreate = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const [
    post,
    postPost,
    getPost,
    loadingCreateEdit,
    editPost,
    successCreateEdit,
    errorCreateEdit,
    resetSuccessError,
    resetPosts,
  ] = usePosts(
    (state) => [
      state.post,
      state.postPost,
      state.getPost,
      state.loadingCreateEdit,
      state.editPost,
      state.successCreateEdit,
      state.errorCreateEdit,
      state.resetSuccessError,
      state.resetPosts,
    ],
    shallow,
  );

  const [value, setValue] = useState(post?.content ? post.content : "");

  useEffect(() => {
    if (post) {
      form.setFieldsValue({
        title: post.title,
        content: post.content,
      });
    }
  }, [post]);

  useEffect(() => {
    return () => {
      resetPosts();
    };
  }, []);

  useEffect(() => {
    if (id !== "create") {
      getPost(id);
    }
  }, [id]);

  useEffect(() => {
    if (successCreateEdit || errorCreateEdit) {
      resetSuccessError();
      if (successCreateEdit) {
        return notification.success({
          message: `Пост ${id === "create" ? "создан" : "отредактирован"}`,
          icon: (
            <SmileOutlined
              style={{
                color: "#06B600",
              }}
            />
          ),
        });
      }
      return notification.error({
        message: "Не удалось создать пост",
        icon: (
          <FrownOutlined
            style={{
              color: "#DD1313",
            }}
          />
        ),
      });
    }
  }, [successCreateEdit, errorCreateEdit, id]);

  const onFinish = (values) => {
    if (id !== "create") {
      editPost(id, values);
    } else {
      postPost(values);
    }
  };
  const onFinishFailed = () => {};

  return (
    <Row>
      <Col span={12}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 60 }}
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          form={form}
        >
          <Form.Item
            label="Заголовок"
            name="title"
            rules={[
              { required: true, message: "Пожалуйста, введите заголовок!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Текст"
            name="content"
            rules={[{ required: true, message: "Пожалуйста, введите текст!" }]}
          >
            <TextEditor />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreateEdit}
            >
              {id !== "create" ? "Сохранить" : "Создать"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ItemCreate;
