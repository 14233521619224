import { createFormData } from "../utils/data";

export const getPosts = async () => {
  const response = await fetch("/api/posts?sort=createdAt,DESC", {});

  if (!response.ok) throw new Error("Unable to fetch posts.");

  return await response.json();
};

export const postPost = async (form) => {
  const response = await fetch(`/api/posts`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to post post." };
  }

  return response.json();
};

export const getPost = async (id) => {
  const response = await fetch(`/api/posts/${id}`);

  if (!response.ok) throw new Error("Unable to get post.");

  return response.json();
};

export const editPost = async (id, form) => {
  const response = await fetch(`/api/posts/${id}`, {
    method: "PATCH",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error("Unable to edit post.");

  return response.json();
};

export const deletePost = async (id) => {
  const response = await fetch(`/api/posts/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error("Unable to delete post.");

  return;
};
