import { create } from "zustand";
import {
  getPosts,
  postPost,
  getPost,
  editPost,
  deletePost,
} from "../services/Posts.service";

export const usePosts = create((set) => ({
  loadingPosts: false,
  posts: [],
  post: null,
  loadingCreateEdit: false,
  successCreateEdit: false,
  errorCreateEdit: false,
  successDelete: false,
  deletePost: async (id) => {
    const posts = usePosts.getState().posts;
    await deletePost(id);
    set({ posts: posts.filter((i) => i.id !== id), successDelete: true });
  },
  resetDelete: () => {
    set({ successDelete: false });
  },
  getPosts: async () => {
    set({ loadingPosts: true });
    const response = await getPosts();
    set({ loadingPosts: false, posts: response });
  },
  editPost: async (id, form) => {
    set({ loadingCreateEdit: true, successCreateEdit: false });
    const item = await editPost(id, form);
    set({ item, loadingCreateEdit: false, successCreateEdit: true });
  },
  postPost: async (values) => {
    set({
      loadingCreateEdit: true,
      successCreateEdit: false,
      errorCreateEdit: false,
    });
    const data = await postPost(values);

    if (data.error) {
      set({
        loadingCreateEdit: false,
        successCreateEdit: false,
        errorCreateEdit: true,
      });
    } else {
      set({ loadingCreateEdit: false, successCreateEdit: true });
    }
  },
  getPost: async (id) => {
    const post = await getPost(id);
    set({ post });
  },
  resetSuccessError: () => {
    set({ successCreateEdit: false, errorCreateEdit: false });
  },
  resetPosts: () => {
    set({ post: null });
  },
}));
