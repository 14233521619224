import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { Button, Form, Input, notification, Col, Row } from "antd";
import { useCollections } from "../../store/collections";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import Items from "./Items";
import Photo from "./Photo";
import Video from "./Video";

const ItemCreate = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const [
    collection,
    postCollection,
    getCollection,
    loadingCreateEdit,
    editCollection,
    successCreateEdit,
    errorCreateEdit,
    resetSuccessError,
    resetCollections,
  ] = useCollections(
    (state) => [
      state.collection,
      state.postCollection,
      state.getCollection,
      state.loadingCreateEdit,
      state.editCollection,
      state.successCreateEdit,
      state.errorCreateEdit,
      state.resetSuccessError,
      state.resetCollections,
    ],
    shallow,
  );

  useEffect(() => {
    if (collection) {
      form.setFieldsValue({
        name: collection.name,
      });
    }
  }, [collection]);

  useEffect(() => {
    return () => {
      resetCollections();
    };
  }, []);

  useEffect(() => {
    if (id !== "create") {
      getCollection(id);
    }
  }, [id]);

  useEffect(() => {
    if (successCreateEdit || errorCreateEdit) {
      resetSuccessError();
      if (successCreateEdit) {
        return notification.success({
          message: `Коллекция ${
            id === "create" ? "создана" : "отредактирована"
          }`,
          icon: (
            <SmileOutlined
              style={{
                color: "#06B600",
              }}
            />
          ),
        });
      }
      return notification.error({
        message: "Не удалось создать коллекцию",
        icon: (
          <FrownOutlined
            style={{
              color: "#DD1313",
            }}
          />
        ),
      });
    }
  }, [successCreateEdit, errorCreateEdit, id]);

  const onFinish = (values) => {
    if (id !== "create") {
      editCollection(id, values);
    } else {
      postCollection(values);
    }
  };
  const onFinishFailed = () => {};

  return (
    <>
      <Row>
        <Col span={9}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
            form={form}
          >
            <Form.Item
              label="Название"
              name="name"
              rules={[
                { required: true, message: "Пожалуйста, введите название!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingCreateEdit}
              >
                {id !== "create" ? "Сохранить" : "Создать"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {id !== "create" && collection && (
          <Col span={8}>
            <Photo collection={collection} />
          </Col>
        )}
        {id !== "create" && collection && (
          <Col span={7}>
            <Video collection={collection} />
          </Col>
        )}
      </Row>
      {id !== "create" && <Items collectionId={id} />}
    </>
  );
};

export default ItemCreate;
