import { createFormData } from "../utils/data";

export const getCollections = async () => {
  const response = await fetch("/api/collections?sort=createdAt,DESC", {});

  if (!response.ok) throw new Error("Unable to fetch collections.");

  return response.json();
};

export const postCollection = async (form) => {
  const response = await fetch(`/api/collections`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to post collection." };
  }

  return response.json();
};

export const getCollection = async (id) => {
  const response = await fetch(`/api/collections/${id}`);

  if (!response.ok) throw new Error("Unable to get collection.");

  return response.json();
};

export const editCollection = async (id, form) => {
  const response = await fetch(`/api/collections/${id}`, {
    method: "PATCH",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error("Unable to edit collection.");

  return response.json();
};

export const archiveCollection = async (collectionId, isArchive) => {
  const response = await fetch(
    `/api/collections/${isArchive ? "unarchive" : "archive"}`,
    {
      method: "POST",
      body: JSON.stringify({ id: collectionId }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    return { error: "Unable to archive collection." };
  }

  const data = await response.json();

  return { ...data };
};

export const uploadPhoto = async (form) => {
  const response = await fetch("/api/collections/upload-photos", {
    method: "POST",
    body: createFormData(form),
  });

  if (!response.ok) {
    return { error: "Unable to upload photo." };
  }

  const data = await response.json();

  return data;
};

export const uploadVideo = async (form) => {
  const response = await fetch("/api/collections/upload-videos", {
    method: "POST",
    body: createFormData(form),
  });

  if (!response.ok) {
    return { error: "Unable to upload video." };
  }

  const data = await response.json();

  return data;
};

export const deletePhoto = async (form) => {
  const response = await fetch("/api/photos", {
    method: "DELETE",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to delete photo." };
  }

  const data = await response.json();

  return { ...data, ...form };
};

export const deleteVideo = async (form) => {
  const response = await fetch("/api/videos", {
    method: "DELETE",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to delete video." };
  }

  const data = await response.json();

  return { ...data, ...form };
};

export const setPhotoOrder = async (form) => {
  const response = await fetch("/api/collections/set-photo-order", {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    return { error: "Unable to set photo order." };
  }

  const data = await response.json();

  return { ...data, ...form };
};
