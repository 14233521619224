import React from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import Items from "./Items";
import ItemCreate from "./Items/create";
import Collections from "./Collections";
import CollectionsCreate from "./Collections/create";
import Posts from "./Posts";
import PostsCreate from "./Posts/create";
import Home from "./Home";
import {
  AppstoreOutlined,
  PictureOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Button } from "antd";
import Logo from "../img/logo-white.svg";
import { useAuth } from "../store/auth";
import { shallow } from "zustand/shallow";
const { Header, Content, Footer, Sider } = Layout;

const items = [
  {
    icon: <PictureOutlined />,
    label: "Карточки товара",
    key: "/items",
  },
  {
    icon: <AppstoreOutlined />,
    label: "Коллекции",
    key: "/collections",
  },
  {
    icon: <ProfileOutlined />,
    label: "Посты",
    key: "/posts",
  },
];
const RoutesLogged = () => {
  const logout = useAuth((state) => state.logout, shallow);
  const navigate = useNavigate();
  const location = useLocation();
  const firstPath = location.pathname.split("/")[1];

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const handleMenuClick = ({ key }) => {
    if (key) {
      navigate(key);
    }
  };
  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="demo-logo-vertical" />
        <div
          style={{ display: "flex", justifyContent: "center", margin: "8px 0" }}
        >
          <Link to={"/"}>
            <img src={Logo} alt={Logo} width={128} />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[`/${firstPath}`]}
          items={items}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Button
                style={{ margin: "16px 32px", cursor: "pointer" }}
                onClick={() => navigate(-1)}
                type="primary"
              >
                Назад
              </Button>
            </div>
            <Link to={"/"}>
              <Button
                style={{ margin: "16px 32px", cursor: "pointer" }}
                onClick={() => logout()}
              >
                Выйти
              </Button>
            </Link>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              textAlign: "center",
              background: colorBgContainer,
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/items" element={<Items />} exact />
              <Route path="/items/:id" element={<ItemCreate />} exact />
              <Route path="/collections" element={<Collections />} exact />
              <Route
                path="/collections/:id"
                element={<CollectionsCreate />}
                exact
              />
              <Route path="/posts" element={<Posts />} exact />
              <Route path="/posts/:id" element={<PostsCreate />} exact />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default RoutesLogged;
