import React, { useEffect, useState } from "react";
import { Button, Upload, Form, Card, Image, Tag } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCollections } from "../../../store/collections";
import { shallow } from "zustand/shallow";
import noPhoto from "../../../img/no-photo.avif";
import SortableList, { SortableItem } from "react-easy-sort";

const Photo = ({ collection }) => {
  const [uploadPhoto, deletePhoto, setPhotoOrder] = useCollections(
    (state) => [state.uploadPhoto, state.deletePhoto, state.setPhotoOrder],
    shallow,
  );

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (collection) {
      setPhotos(collection.photos.sort((a, b) => (a.index > b.index ? 1 : -1)));
    }
  }, [collection]);

  const onSortEnd = (oldIndex, newIndex) => {
    const newPhotosArray = [...photos];
    const actionItem = newPhotosArray.splice(oldIndex, 1)[0];
    newPhotosArray.splice(newIndex, 0, actionItem);

    setPhotoOrder({
      collectionId: collection.id,
      ids: newPhotosArray.map((i) => i.id),
    });
  };

  return (
    <Form.Item>
      <Upload
        customRequest={({ file }) =>
          uploadPhoto({ file, collectionId: collection.id })
        }
        showUploadList={false}
        multiple
        accept="image/png, image/jpeg"
      >
        <Button icon={<UploadOutlined />} style={{ marginBottom: "16px" }}>
          Загрузить фото
        </Button>
      </Upload>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {photos.map((photo) => (
          <SortableItem key={photo.id}>
            <Card style={{ cursor: "grab", marginBottom: "8px" }}>
              <div style={{ display: "flex" }}>
                <Image
                  alt={photo.id}
                  height={100}
                  preview
                  src={photo.url}
                  // style={{ height: "240px", objectFit: "cover" }}
                  onError={(e) => (e.target.src = noPhoto)}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginLeft: "16px",
                  }}
                >
                  <Tag color="blue">{photo.id}</Tag>
                  <Tag
                    color="geekblue"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Позиция {photo.index}
                  </Tag>
                  <Button
                    type="primary"
                    onClick={() => deletePhoto({ id: photo.id })}
                  >
                    Удалить
                  </Button>
                </div>
              </div>
            </Card>
          </SortableItem>
        ))}
      </SortableList>
    </Form.Item>
  );
};

export default Photo;
