import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Card,
  Skeleton,
  Button,
  Image,
  Popconfirm,
  notification,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useItems } from "../../store/items";
import noPhoto from "../../img/no-photo.avif";
import { shallow } from "zustand/shallow";

const { Meta } = Card;
const cardStyles = {
  display: "flex",
  flexDirection: "column",
  flex: "0 1 24%",
  marginBottom: "16px",
  padding: "0 5px",
  maxWidth: "24%",
};
const Items = ({ collectionId }) => {
  const [
    items,
    getItems,
    loadingItems,
    deleteItem,
    resetDelete,
    successDelete,
    setItemOrder,
  ] = useItems(
    (state) => [
      state.items,
      state.getItems,
      state.loadingItems,
      state.deleteItem,
      state.resetDelete,
      state.successDelete,
      state.setItemOrder,
    ],
    shallow,
  );

  useEffect(() => {
    getItems(collectionId ? collectionId : null);
  }, [collectionId]);

  useEffect(() => {
    if (successDelete) {
      notification.success({
        message: "Карточка товара удалена",
        icon: (
          <SmileOutlined
            style={{
              color: "#06B600",
            }}
          />
        ),
      });
      resetDelete();
    }
  }, [successDelete]);

  const skeletonCard = (
    <div style={cardStyles}>
      <Card
        style={cardStyles}
        actions={[
          <SettingOutlined key="setting" />,
          <EditOutlined key="edit" />,
          <EllipsisOutlined key="ellipsis" />,
        ]}
      >
        <Skeleton loading={loadingItems} avatar active>
          <Meta
            avatar={
              <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
            }
            title="Card title"
            description="This is the description"
          />
        </Skeleton>
      </Card>
    </div>
  );

  // const onSortEnd = (oldIndex, newIndex) => {
  //   const newItemsArray = [...items];
  //   const actionItem = newItemsArray.splice(oldIndex, 1)[0];
  //   newItemsArray.splice(newIndex, 0, actionItem);
  //
  //   setItemOrder({ ids: newItemsArray.map((i) => i.id) });
  // };

  return (
    <div>
      {!collectionId && (
        <Button
          style={{
            display: "flex",
            marginBottom: "16px",
          }}
          type="primary"
        >
          <Link to={"/items/create"}>Создать карточку</Link>
        </Button>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {loadingItems
          ? [...Array(4)].map((i) => skeletonCard)
          : items.map((item) => (
              <div style={cardStyles} key={items.id}>
                <Card
                  key={item.id}
                  cover={
                    <Image
                      alt={item.id}
                      preview={Boolean(item.photos[0]?.url)}
                      src={item.photos[0]?.url ? item.photos[0].url : ""}
                      style={{ height: "240px", objectFit: "cover" }}
                      onError={(e) => (e.target.src = noPhoto)}
                    />
                  }
                  actions={[
                    <Link to={`/items/${item.id}`}>
                      <EditOutlined key="edit" />
                    </Link>,
                    <Popconfirm
                      placement="top"
                      title="Вы уверены, что хотите удалить карточку?"
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={() => deleteItem(item.id)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>,
                  ]}
                >
                  <Meta
                    title={item.name}
                    description={
                      item.price
                        ? `${item.price.toLocaleString("ru-RU")} ₽`
                        : ""
                    }
                  />
                </Card>
              </div>
            ))}
      </div>
    </div>
  );
};
export default Items;
