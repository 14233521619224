import logo from "./logo.svg";
import "./App.css";

import { ApiClient, AppApi } from "@solar/api-client-js";
import { BrowserRouter } from "react-router-dom";
import RoutesComponent from "./routes";
import { api } from "./api";

function App() {
  return <RoutesComponent />;
}

export default App;
