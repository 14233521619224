import React, { useEffect } from "react";
import { Card, Image, Typography, Tag } from "antd";
import { useItems } from "../../../store/items";
import noPhoto from "../../../img/no-photo.avif";
import { shallow } from "zustand/shallow";
import SortableList, { SortableItem } from "react-easy-sort";
import { typesArray } from "../../../utils/data";
import { Link } from "react-router-dom";
const Items = ({ collectionId }) => {
  const [items, getItems, setItemOrder, resetItems] = useItems(
    (state) => [
      state.items,
      state.getItems,
      state.setItemOrder,
      state.resetItems,
    ],
    shallow,
  );

  useEffect(() => {
    getItems(collectionId);
  }, [collectionId]);

  useEffect(() => {
    return () => {
      resetItems();
    };
  }, []);

  const onSortEnd = (oldIndex, newIndex, itemsByType, type) => {
    const newItemsArray = [...itemsByType];
    const actionItem = newItemsArray.splice(oldIndex, 1)[0];
    newItemsArray.splice(newIndex, 0, actionItem);

    setItemOrder({ ids: newItemsArray.map((i) => i.id), collectionId, type });
  };

  return (
    <div style={{ display: "flex" }}>
      {typesArray.map((type) => {
        const itemsByType = items.filter((i) => i.type === type.id);
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 10px",
              flex: "0 1 20%",
            }}
          >
            <h3>{type.name}</h3>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <SortableList
                onSortEnd={(oldIndex, newIndex) =>
                  onSortEnd(oldIndex, newIndex, itemsByType, type.id)
                }
                className="list"
                draggedItemClassName="dragged"
              >
                {itemsByType.map((item) => (
                  <SortableItem key={item.id}>
                    <Card style={{ marginBottom: "8px", cursor: "grab" }}>
                      <div style={{ display: "flex" }}>
                        <Image
                          alt={item.id}
                          height={120}
                          preview
                          src={item.photos.length && item.photos[0].url}
                          onError={(e) => (e.target.src = noPhoto)}
                          style={{ minWidth: "76px", objectFit: "cover" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "16px",
                            justifyContent: "center",
                          }}
                        >
                          <Typography style={{ marginBottom: "8px" }}>
                            <Link to={`/items/${item.id}`}>{item.name}</Link>
                          </Typography>
                          <Tag color="geekblue">Позиция {item.index}</Tag>
                        </div>
                      </div>
                    </Card>
                  </SortableItem>
                ))}
              </SortableList>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Items;
