import { create } from "zustand";
import {
  archiveCollection,
  editCollection,
  getCollection,
  getCollections,
  postCollection,
  uploadPhoto,
  deletePhoto,
  setPhotoOrder,
  uploadVideo,
  deleteVideo,
} from "../services/Collections.service";

export const useCollections = create((set) => ({
  loadingCollections: false,
  loadingArchiveCollectionId: "",
  collections: [],
  collection: null,
  loadingCreateEdit: false,
  successCreateEdit: false,
  errorCreateEdit: false,
  setPhotoOrder: async (form) => {
    const data = await setPhotoOrder(form);
    const collection = useCollections.getState().collection;
    if (data.error) {
      return;
    } else {
      set({
        collection: {
          ...collection,
          photos: data.ids.map((id, idx) => {
            const findedPhoto = collection.photos.find(
              (photo) => photo.id === id,
            );
            return { ...findedPhoto, index: idx + 1 };
          }),
        },
      });
    }
  },
  deletePhoto: async (form) => {
    const data = await deletePhoto(form);
    const collection = useCollections.getState().collection;
    if (data.error) {
      return;
    } else {
      set({
        collection: {
          ...collection,
          photos: collection.photos.filter((photo) => photo.id !== data.id),
        },
      });
    }
  },
  deleteVideo: async (form) => {
    const data = await deleteVideo(form);
    const collection = useCollections.getState().collection;
    if (data.error) {
      return;
    } else {
      set({
        collection: {
          ...collection,
          videos: collection.videos.filter((video) => video.id !== data.id),
        },
      });
    }
  },
  uploadVideo: async (form) => {
    const collection = useCollections.getState().collection;
    const data = await uploadVideo(form);
    if (data.error) {
      return;
    } else {
      set({
        collection: {
          ...collection,
          videos: collection.videos.concat(data),
        },
      });
    }
  },
  uploadPhoto: async (form) => {
    const collection = useCollections.getState().collection;
    const data = await uploadPhoto(form);
    if (data.error) {
      return;
    } else {
      set({
        collection: {
          ...collection,
          photos: collection.photos.concat(data),
        },
      });
    }
  },
  resetSuccessError: () => {
    set({ successCreateEdit: false, errorCreateEdit: false });
  },
  editCollection: async (id, form) => {
    set({ loadingCreateEdit: true, successCreateEdit: false });
    const item = await editCollection(id, form);
    set({ item, loadingCreateEdit: false, successCreateEdit: true });
  },
  postCollection: async (values) => {
    set({
      loadingCreateEdit: true,
      successCreateEdit: false,
      errorCreateEdit: false,
    });
    const data = await postCollection(values);

    if (data.error) {
      set({
        loadingCreateEdit: false,
        successCreateEdit: false,
        errorCreateEdit: true,
      });
    } else {
      set({ loadingCreateEdit: false, successCreateEdit: true });
    }
  },
  getCollections: async () => {
    set({ loadingCollections: true });
    const response = await getCollections();
    set({ loadingCollections: false, collections: response.data });
  },
  getCollection: async (id) => {
    const collection = await getCollection(id);
    set({ collection });
  },
  archiveCollection: async (collectionId, isArchive) => {
    const collections = useCollections.getState().collections;
    set({ loadingArchiveCollectionId: collectionId });
    const data = await archiveCollection(collectionId, isArchive);
    if (data.error) {
      set({ loadingArchiveCollectionId: "" });
      return;
    } else {
      set({
        collections: collections.map((c) => ({
          ...c,
          isArchived: c.id === collectionId ? !c.isArchived : c.isArchived,
        })),
        loadingArchiveCollectionId: "",
      });
    }
  },
  resetCollections: () => {
    set({ collection: null });
  },
}));
