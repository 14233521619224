import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Skeleton, Button, Popconfirm, notification } from "antd";
import { DeleteOutlined, EditOutlined, SmileOutlined } from "@ant-design/icons";
import { shallow } from "zustand/shallow";
import { usePosts } from "../../store/posts";

const { Meta } = Card;
const cardStyles = {
  display: "flex",
  flexDirection: "column",
  flex: "0 1 24%",
  marginBottom: "16px",
  padding: "0 5px",
};
const Posts = () => {
  const [
    posts,
    getPosts,
    loadingPosts,
    deletePost,
    successDelete,
    resetDelete,
  ] = usePosts(
    (state) => [
      state.posts,
      state.getPosts,
      state.loadingPosts,
      state.deletePost,
      state.successDelete,
      state.resetDelete,
    ],
    shallow,
  );

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    if (successDelete) {
      notification.success({
        message: "Пост удален",
        icon: (
          <SmileOutlined
            style={{
              color: "#06B600",
            }}
          />
        ),
      });
      resetDelete();
    }
  }, [successDelete]);

  const skeletonCard = (
    <div style={cardStyles}>
      <Card style={cardStyles} actions={[<EditOutlined key="edit" />]}>
        <Skeleton loading={loadingPosts} avatar active>
          <Meta title="Card title" />
        </Skeleton>
      </Card>
    </div>
  );

  return (
    <div>
      <Button
        style={{
          display: "flex",
          marginBottom: "16px",
        }}
        type="primary"
      >
        <Link to={"/posts/create"}>Создать пост</Link>
      </Button>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {loadingPosts
          ? [...Array(4)].map((i) => skeletonCard)
          : posts.map((post) => (
              <div style={cardStyles} key={post.id}>
                <Card
                  key={post.id}
                  actions={[
                    <Link to={`/posts/${post.id}`}>
                      <EditOutlined key="edit" />
                    </Link>,
                    <Popconfirm
                      placement="top"
                      title="Вы уверены, что хотите удалить пост?"
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={() => deletePost(post.id)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>,
                  ]}
                >
                  <Meta title={post.title} />
                </Card>
              </div>
            ))}
      </div>
    </div>
  );
};
export default Posts;
